// src/hooks/useArticles.ts
import {useState, useEffect, useCallback, useRef} from 'react';
import axios from 'axios';
import {Platform} from 'react-native';
import debounce from 'lodash/debounce';

const API_URL = 'api/articles/'

type Article = {
  id: number;
  title: string;
  digest: string;
  text: string;
  source: {name: string};
  topics: {slug: string; label: string}[];
  tags: {slug: string; label: string}[];
  publication_datetime: string;
};

type ApiResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  results: Article[];
};

const useArticles = () => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [loading, setLoading] = useState(true);
  const [nextUrl, setNextUrl] = useState<string | null>(API_URL);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const isFetchingMoreRef = useRef(false);
  const fetchedUrlsRef = useRef<Set<string>>(new Set());

  useEffect(() => {
    const fetchInitialArticles = async () => {
      setLoading(true);
      setIsFetchingMore(true);
      isFetchingMoreRef.current = true;
      fetchedUrlsRef.current.add(API_URL);

      try {
        const response = await axios.get<ApiResponse>(API_URL);
        setArticles(response.data.results);
        setNextUrl(response.data.next);
        setHasMore(!!response.data.next);
      } catch (error) {
        console.error('Error fetching articles:', error);
      } finally {
        setLoading(false);
        setIsFetchingMore(false);
        isFetchingMoreRef.current = false;
      }
    };

    fetchInitialArticles();
  }, []);

  const fetchMoreArticles = useCallback(async () => {
    if (
      !nextUrl ||
      isFetchingMoreRef.current ||
      !hasMore ||
      fetchedUrlsRef.current.has(nextUrl)
    ) {
      console.log('Fetch prevented:', {
        nextUrl,
        isFetchingMore: isFetchingMoreRef.current,
        hasMore,
      });
      return;
    }

    isFetchingMoreRef.current = true;
    setIsFetchingMore(true);
    fetchedUrlsRef.current.add(nextUrl);

    console.log('Fetching more articles from:', nextUrl);

    try {
      const response = await axios.get<ApiResponse>(nextUrl);
      setArticles(prevArticles => {
        const newArticles = response.data.results.filter(
          article =>
            !prevArticles.some(prevArticle => prevArticle.id === article.id),
        );
        return [...prevArticles, ...newArticles];
      });
      setNextUrl(response.data.next);
      setHasMore(!!response.data.next);
    } catch (error) {
      console.error('Error fetching more articles:', error);
    } finally {
      isFetchingMoreRef.current = false;
      setIsFetchingMore(false);
    }
  }, [nextUrl, hasMore]);

  // Web-specific scroll handling
  useEffect(() => {
    if (Platform.OS === 'web') {
      const handleWindowScroll = debounce(() => {
        const scrollTop = window.scrollY;
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;

        if (scrollTop + windowHeight >= documentHeight - 100) {
          fetchMoreArticles();
        }
      }, 200);

      window.addEventListener('scroll', handleWindowScroll);
      return () => {
        window.removeEventListener('scroll', handleWindowScroll);
      };
    }
  }, [fetchMoreArticles]);

  return {
    articles,
    loading,
    isFetchingMore,
    fetchMoreArticles,
  };
};

export default useArticles;

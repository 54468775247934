// src/components/ArticleList.tsx
import React from 'react';
import {
  FlatList,
  ActivityIndicator,
  Text,
  Platform,
  StyleSheet,
} from 'react-native';
import ArticleItem from './ArticleItem';

type Article = {
  id: number;
  title: string;
  digest: string;
  text: string;
  source: {name: string};
  topics: {slug: string; label: string}[];
  tags: {slug: string; label: string}[];
  publication_datetime: string;
};

type Props = {
  articles: Article[];
  loading: boolean;
  isFetchingMore: boolean;
  onEndReached: () => void;
};

const ArticleList: React.FC<Props> = ({
  articles,
  loading,
  isFetchingMore,
  onEndReached,
}) => (
  <>
    {loading && articles.length === 0 ? (
      <ActivityIndicator size="large" color="#0000ff" />
    ) : (
      <FlatList
        data={articles}
        renderItem={({item}) => <ArticleItem article={item} />}
        keyExtractor={item => item.id.toString()}
        contentContainerStyle={styles.listContent}
        ListFooterComponent={
          isFetchingMore ? (
            <ActivityIndicator size="small" color="#0000ff" />
          ) : null
        }
        ListEmptyComponent={
          !loading && articles.length === 0 ? (
            <Text style={styles.noDataText}>No articles available</Text>
          ) : null
        }
        onEndReached={Platform.OS !== 'web' ? onEndReached : undefined}
        onEndReachedThreshold={Platform.OS !== 'web' ? 0.5 : undefined}
      />
    )}
  </>
);

const styles = StyleSheet.create({
  listContent: {
    paddingVertical: 16,
  },
  noDataText: {
    textAlign: 'center',
    fontSize: 18,
    marginTop: 20,
  },
});

export default ArticleList;

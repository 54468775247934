import React, { useEffect } from 'react';
import {
  SafeAreaView,
  StatusBar,
  useColorScheme,
  Platform,
  StyleSheet,
  View,
} from 'react-native';
import ReactGA from 'react-ga4';
import ArticleList from './components/ArticleList';
import useArticles from './hooks/useArticles';

const App = (): React.JSX.Element => {
  const { articles, loading, isFetchingMore, fetchMoreArticles } = useArticles();
  const isDarkMode = useColorScheme() === 'dark';

  const backgroundStyle = {
    backgroundColor: isDarkMode ? '#1e1e1e' : '#f2f2f2',
  };

  useEffect(() => {
    // Initialize GA with your stream ID
    ReactGA.initialize('G-V7RECPVWQH');
    // Log the initial page view
    ReactGA.send('pageview');
  }, []);

  return (
    <SafeAreaView style={[styles.safeArea, backgroundStyle]}>
      {Platform.OS !== 'web' && (
        <StatusBar
          barStyle={isDarkMode ? 'light-content' : 'dark-content'}
          backgroundColor={backgroundStyle.backgroundColor}
        />
      )}
      <View style={styles.container}>
        <ArticleList
          articles={articles}
          loading={loading}
          isFetchingMore={isFetchingMore}
          onEndReached={fetchMoreArticles}
        />
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
  },
  container: {
    flex: 1,
    paddingHorizontal: 16,
    paddingTop: Platform.OS === 'web' ? 20 : 0,
    maxWidth: 800,
    alignSelf: 'center',
    width: '100%',
  },
});

export default App;